<template>
  <v-dialog
    :retain-focus="false"
    max-width="1200"
    style="overflow-x: hidden"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card tile>
      <v-toolbar style="flex: 0 1 auto" height="60px" flat dark color="primary">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> Import students list </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn @click.prevent="$emit('close')" color="silver" class="mr-1">
          {{ $t("close") }}
        </v-btn>
      </v-toolbar>

      <div class="container">
        <v-form
          class="pa-4"
          ref="form"
          @submit.prevent="onSave"
          v-model="valid"
        >
          <div class="table">
            <v-data-table
              :loading="hasLoadingList"
              :loading-text="$t('loadingDataWaiting')"
              :headers="headers"
              :items="parseListValid"
              hide-default-footer
              :items-per-page="!!parseListValid && parseListValid.length ? parseListValid.length : 0"
              class="elevation-1"
            >
              <template v-slot:item.full_name="{ item }">
                <v-text-field
                  v-model="item.full_name"
                  :error-messages="validationErrors.full_name || item.full_name_validError"
                  :rules="fullNameRules"
                  ref="full_name"
                  required
                  class="input-field"
                  size="25"
                ></v-text-field>
              </template>
              <template v-slot:item.email="{ item }">
                <v-text-field
                  v-model="item.email"
                  :error-messages="validationErrors.email || item.email_validError"
                  :rules="emailRules"
                  ref="email"
                  required
                  class="input-field"
                  size="30"
                ></v-text-field>
              </template>
              <template v-slot:item.phone="{ item }">
                <v-text-field
                  v-model="item.phone"
                  :error-messages="validationErrors.phone || item.phone_validError"
                  :rules="phoneRules"
                  ref="phone"
                  required
                  class="input-field"
                  size="30"
                ></v-text-field>
              </template>
              <template v-slot:item.country_id="{ item }">
                <!-- {{ item.country.data.name }} -->
              <v-select
                v-model="item.country_id"
                :items="defaultCountries"
                :error-messages="validationErrors.country || ''"
                :rules="countryRules"
                item-text="name"
                item-value="id"
                required
            ></v-select>

              </template>
              <template v-slot:item.packages="{ item }">
                <div class="table__labels">
                  <div class="table__label" v-for="(label, index) in item.packages.data" :key="index">
                    {{ label.name }}
                    <v-icon class="table__label-icon" left @click="deletePackage(item, index)">close</v-icon>
                  </div>
                </div>
              </template>
              <template v-slot:item.locale="{ item }">
               <v-select
                v-model="item.locale"
                :items="interfaceLanguagesNames"

                :error-messages="validationErrors.interface_language || ''"
                :rules="interfaceRules"
                item-text="name"
                item-value="slug"
                ></v-select>
              </template>
              <template v-slot:item.user="{ item }">
                {{ item.user.data.id || "-" }}
              </template>

              <template v-slot:item.account_type="{ item }">
              <v-select
                v-model="item.account_type"
                :items="userTypes"
                :label="$t('type')"
                :error-messages="validationErrors.type_id || ''"
                item-text="title"
                item-value="name"
                required
              ></v-select>
              </template>

              <template v-slot:item.actions="{ item }">
                <form-checkbox
                  v-if="item.user.data.id"
                  :checked="+item.is_selected"
                  :name="item.email"
                  label="Update"
                  @change="onChangeCheck(item, $event)"
                ></form-checkbox>
              </template>
            </v-data-table>
          </div>

          <v-btn
            @click.prevent="onSave()"
            :loading="hasLoadingList"
            color="success"
            class="mr-1"
          >
            Import
          </v-btn>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { map } from "lodash";

import FormCheckbox from "@/components/forms/FormCheckbox";
import packages from "../../../../../store/modules/courses/packages";
import validationMixin from '@/mixins/validation'

export default {
  name: "index",
  mixins: [validationMixin],
  components: {
    FormCheckbox,
  },
  data() {
    return {
      dialog: true,

      headers: [
        {
          text: "Full name",
          value: "full_name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Phone",
          value: "phone",
        },
        {
          text: "Country",
          value: "country_id",
        },
        {
          text: "Packages",
          value: "packages",
        },
        {
          text: "User Id in system",
          value: "user",
        },
        {
          text: "Interface language",
          value: "locale",
        },
        {
          text: "Account Type",
          value: "account_type",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        }
      ],
      valid: false,
      validationErrors: {
        full_name: '',
        email: '',
        phone: ''
      }
    }
  },

  computed: {
    ...mapState({
      parseList: (state) => state.userImports.parseList,
      hasLoadingList: (state) => state.userImports.hasLoadingList,
    }),
    ...mapGetters({
      profile: 'profile/profile',
      countries: 'setting/countries',
      interfaceLanguages: 'setting/interfaceLanguages',
      userTypes: 'userSetting/userTypes',
    }),
    defaultCountries () {
      if (this.profile) {
        const list = this.profile.countries.data.filter(e => e.type === 'access')
        return list.map(e => e.country.data)
      }
      return []
    },
    interfaceLanguagesNames() {
      if (!!this.interfaceLanguages && this.interfaceLanguages.length) return this.interfaceLanguages.map(item => ({
          ...item,
          name: item.locale
      }))
    },
    parseListValid () {
      return this.parseList.map(item => ({
        ...item,
        full_name_validError: '',
        email_validError: '',
        phone_validError: ''
      }))
    }
  },
   created () {
    if (!this.interfaceLanguages) {
      this.fetchInterface({id: 242})
    }
    if (!this.userTypes) {
      this.fetchUsersSetting()
    }
   },
  methods: {
    ...mapMutations({
      CHANGE_SELECTED_STATUS: "userImports/CHANGE_SELECTED_STATUS",
    }),
    ...mapActions({
      SAVE_PARSE_LIST: "userImports/SAVE_PARSE_LIST",
      fetchCountries: 'setting/GET_COUNTRIES',
      fetchInterface: 'setting/GET_LANGUAGE_INTERFACE_LIST',
      fetchUsersSetting: 'userSetting/GET_USERS_SETTING'
    }),

    onSave () {
      let list = []

      this.parseListValid.forEach((el) => {
        // this.$refs.full_name.validate()
        let packagesIds = map(el.packages.data, (pack) => {
          return pack.id
        });

        this.userTypes.forEach((cntr) => {
          if(cntr.title == el.account_type) {
            el.account_type = cntr.id;
          }
        });

        list.push({
          email: el.email,
          full_name: el.full_name,
          phone: el.phone,
          packages: packagesIds.join(","),
          default_country_id: el.country_id,
          country_id: el.country_id,
          locale: el.locale,
          account_type: el.account_type,
          update: el.is_selected,
        });
      });

      let formData = new FormData();

      list.forEach((el, index) => {
        formData.append(`users[${index}][email]`, el.email);
        formData.append(`users[${index}][full_name]`, el.full_name);
        formData.append(`users[${index}][phone]`, el.phone);
        formData.append(`users[${index}][packages]`, el.packages);
        formData.append(`users[${index}][country_id]`, el.country_id);
        formData.append(`users[${index}][interface_language]`, el.locale);
        formData.append(`users[${index}][type_id]`, el.account_type);
        formData.append(
          `users[${index}][default_country_id]`,
          el.default_country_id
        );
        formData.append(`users[${index}][update]`, el.update);
      });

      this.SAVE_PARSE_LIST(formData).then(() => {
        this.$emit('close')
      }).catch((error) => {
        const validation = error.response.data.validation
        this.parseListValid.forEach((el, index) => {
          if (typeof validation[`users.${index}.phone`] !== 'undefined') {
            el.phone_validError = validation[`users.${index}.phone`][0]
          } else {
            el.phone_validError = ''
          }
          if (typeof validation[`users.${index}.email`] !== 'undefined') {
            el.email_validError = validation[`users.${index}.email`][0]
          } else {
            el.email_validError = ''
          }
          if (typeof validation[`users.${index}.full_name`] !== 'undefined') {
            el.full_name_validError = validation[`users.${index}.full_name`][0]
          } else {
            el.full_name_validError = ''
          }
        })
      })
    },

    onChangeCheck(item, status) {
      this.CHANGE_SELECTED_STATUS({
        item: item,
        status: status,
      });
    },
    deletePackage (item, id) {
      item.packages.data.splice(id, 1)
    }
  },
};
</script>

<style scoped lang=scss>
.table {
  margin-bottom: 30px;
  &__labels {
    display: flex;
    flex-wrap: wrap;
  }
  &__label {
    font-size: 12px;
    background: green;
    padding: 3px 10px;
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    color: #fff;
    &-icon {
      margin-left: 5px;
      margin-right: 0;
      font-size: 12px;
      color: #fff;
      cursor: pointer;
    }
  }
  .input-field {
    font-size: 0.875rem;
  }
}
</style>
