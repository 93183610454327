<template>
	<v-dialog
			:retain-focus="false"
			max-width="800"
			style="overflow-x: hidden"
			v-model="dialog"
			persistent
			transition="dialog-bottom-transition"
			scrollable
	>
		<v-card tile>
			<v-toolbar
					style="flex: 0 1 auto;"
					height="60px"
					flat
					dark
					color="primary"
			>
				<v-btn
						icon
						dark
						@click="$emit('close')"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>
					Import students
				</v-toolbar-title>
				<v-spacer></v-spacer>

				<v-btn @click.prevent="$emit('close')"
						color="silver"
						class="mr-1"
				>
					{{ $t('close') }}
				</v-btn>
			</v-toolbar>

			<div class="container">
				<div class="import">
					<v-row>
						<v-col cols="8" class="pl-5 pr-5 pb-0">

							<div class="import__file">
								<v-file-input v-model="file"
										label="Load file"
								></v-file-input>
							</div>

							<v-btn @click.prevent="onParse()"
									:class="{'has-disabled': !file}"
									:loading="hasLoadingParse"
									color="success"
									class="mr-1">
								{{ $t('save') }}
							</v-btn>
						</v-col>

						<v-col cols="4" class="pl-5 pr-5 pb-0">
							<ul class="import__list">
								<li class="import__item" v-for="(link, key) in examples">
									<a :href="link" download="download" target="_blank">Пример {{key}}</a>
								</li>
							</ul>
						</v-col>


					</v-row>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
	import {mapState, mapActions, mapGetters, mapMutations } from 'vuex'

	export default {
		name: 'index',
		data(){
			return {
				dialog: true,
				file: null
			}
		},
		computed:{
			...mapState({
				examples: state => state.userImports.examples,
				hasLoadingParse: state => state.userImports.hasLoadingParse
			})
		},

		methods:{
			...mapActions({
				RUN_PARSE: 'userImports/RUN_PARSE'
			}),

			onParse(){
				let formData = new FormData;
				formData.append('file', this.file);

				this.RUN_PARSE(formData).then(() =>{
					this.$emit('openTable');
				})
			},
		}
	};
</script>

<style scoped lang=scss>
	.import{
		margin-bottom: 20px;
		&__item{
			margin-bottom: 10px;
		}
	}
</style>
