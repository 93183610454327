import mixins from '@/mixins'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import moment from 'moment'
import CountryFlag from 'vue-country-flag'

import DialogImportLoad from '../../components/dialog-import-load/index.vue';
import DialogImportQueue from '../../components/dialog-import-queue/index.vue';
import DialogImportTable from '../../components/dialog-import-table/index.vue';

export default {
  name: 'user-students-list',
  mixin: [mixins],
  components:{
	  CountryFlag,
	  DialogImportLoad,
    DialogImportQueue,
	  DialogImportTable
  },
  data () {
    return {
      page: 1,
      pageCount: 20,
      itemsPerPage: 20,
      showRemoveDialog: false,
      removingItem: null,
      filter: {
        keywords: '',
        batch: '',
        country: '',
        course: '',
        isActive: true,
        courseUserId: ''
      },
      courseKeyword: '',
      searchCourseElem: '',
      searchInputCourse: '',

      hasModalImportLoad: false,
      hasModalImportQueue: false,
      hasModalImportTable: false
    }
  },
  filters: {
    formattedDate (value) {
      if (!value) return ''
      return moment(value).format('DD.MM.YYYY HH:mm')
    }
  },
  computed: {
    ...mapGetters({
      isSimpleCourseLoading: 'courses/isSimpleCourseLoading',
      simpleCourses: 'courses/simpleCourses',
      courseUsersIds: 'courses/courseUsersIds',
      isCourseUsersIdsLoading: 'courses/isCourseUsersIdsLoading',

      list: 'userStudents/list',
      loading: 'userStudents/isListLoading',
      lengthList: 'userStudents/listLength',
      skip: 'userStudents/skip',
      limit: 'userStudents/limit',
      isLoading: 'userStudents/isLoading',
      filterStore: 'userStudents/filter',
      accessCountries: 'profile/accessCountries',
      userData: 'userStudents/userData'

    }),
    headers () {
      return [
        {
          text: 'Id',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Full name',
          value: 'fullName'
        },
        {
          text: 'E-Mail',
          value: 'email'
        },
        {
          text: 'Phone',
          value: 'phone'
        },
        {
          text: 'Points',
          value: 'points'
        },
        {
          text: 'Country',
          value: 'country'
        },
        {
          text: 'Created time',
          value: 'create_at'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.setDefaultFilter()
    this.fetchList()
    if (this.list) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'userStudents/FETCH_LIST',
      removeListItem: 'userStudents/DELETE_USER',

      fetchCourses: 'courses/GET_COURSES_SIMPLE_RESPONSE',
      fetchCoursesUsersIds: 'courses/GET_COURSE_USERS_IDS',
      fetchUserData: 'userStudents/GET_USER_DATA'
    }),
    ...mapMutations({
      changeFilterStore: 'userStudents/CHANGE_FILTER',
      changeSkip: 'userStudents/CHANGE_SKIP'
    }),

	  onOpenTable(){
        this.hasModalImportLoad = false;
        this.hasModalImportTable = true;
      },

    changeInputAutocomplete: debounce(function (val) {
      if (!val) return
      if (this.courseKeyword !== val) {
        this.courseKeyword = val
        this.fetchCourses({
          keywords: val
        })
      }
    }, 500),
    autoCompleteChangedCourse (e) {
      this.searchCourseElem = e
      this.fetchCoursesUsersIds({ id: e.id }).then(() => {
        this.filter.courseUserId = this.courseUsersIds
        this.changeFilter()
      })
    },
    setDefaultFilter () {
      Object.assign(this.filter, this.filterStore)
    },
    changeFilter (type = '') {
      if (type === 'course') {
        this.courseKeyword = ''
        this.searchCourseElem = ''
        this.filter.courseUserId = ''
      }
      this.changeFilterStore(this.filter)
      this.sendRequest()
    },
    removeUser () {
      this.removeListItem(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('successUserDelete'))
      })
    },
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      this.removingItem = item
    },
    sendRequest: debounce(function () {
      this.fetchList(this.filter)
    }, 1000),
    logInLikeUser (item, path) {
      this.fetchUserData(item).then(() => {
        window.open(
          `${process.env.VUE_APP_ROOT_LINK}${path}?user_token=${this.userData[0].token}&user_refresh=${this.userData[1].token}&session-id=${localStorage.getItem('session-id')}`,
          '_blank'
        )
      })
    }
  }
}
