<template>
	<div class="checkbox">
		<label class="checkbox__wrap">
			<input type="checkbox"
					class="checkbox__area"
					:name="name"
					:checked="checked"
					@change="$emit('change', +$event.target.checked)">
			<i class="checkbox__icon"></i>
			<span class="checkbox__label">{{label}}</span>
		</label>
	</div>
</template>

<script>
	export default {
		name: 'form-switcher',
		model: {
			prop: 'checked',
			event: 'change'
		},
		props:{
			checked: {
				required: true
			},
			name: {
				type: String,
				required: true
			},
			type: String,
			label: String,
			rules: String,
			errorText: String
		}
	};
</script>

<style scoped lang=scss>
	.checkbox{
		position: relative;
		&__wrap{
			position: relative;
			display: flex;
			align-items: center;
			cursor: pointer;
		}
		&__icon {
			position: relative;
			display: block;
			width: 30px;
			height: 7px;
			border-radius: 50px;
			background: #dedede;
			&:before{
				content: '';
				width: 16px;
				height: 16px;
				border-radius: 50%;
				position: absolute;
				top: -5px;
				background: #bdbdbd;
				right: 14px;
				transition: all 0.3s;
				box-shadow: 0 2px 7px rgba(0,0,0,0.3);
			}
		}
		&__label {
			margin: 0 5px;
			font-size: 12px;
			padding: 0 5px;
			text-transform: uppercase;
			font-weight: bold;
		}
		&__area {
			display: none;
			&:checked {
				& + .checkbox__icon{
					background: #4CAF50;
					&:before{
						right: 0px;
						background: #4CAF50;
					}
				}
			}
		}
	}


	.checkbox{
		&.has-sm{
			span{
				font-size: 9px;
			}
		}
	}


</style>
